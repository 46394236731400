import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import PortfolioBlock from "../components/Portfolio"

const SecondPage = () => (
  <Layout>

    <Seo title="Portfolio " />
    
    {/* <PortfolioBlock />  */}
  </Layout>
)

export default SecondPage
